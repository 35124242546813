const ROUTES = {
  home: '/',
  login: '/login',
  newPickup: '/admin/new-pickup',
  careers: '/careers',
  pricing: '/pricing',
  about: '/about',
};

export default ROUTES;
