import * as React from 'react';
import loadable from '@loadable/component';
import GradientText from 'components/GradientText/GradientText';
import HeroContainer from 'components/HeroContainer/HeroContainer';
import { useTheme, Box, Typography, Stack, useMediaQuery, BoxProps } from '@mui/material';
import GetStartedButtons from './GetStartedButtons';

function createLoading(sx: BoxProps['sx']) {
  return () => (
    <Box
      sx={{
        borderRadius: 1,
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'primaryDark.800' : 'grey.100'),
        ...sx,
      }}
    />
  );
}

const TaskCard = loadable(() => import('components/Showcase/TaskCard'), {
  ssr: false,
  fallback: createLoading({ width: 360, height: 280 }),
});
const ThemeToggleButton = loadable(() => import('components/Showcase/ThemeToggleButton'), {
  ssr: false,
  fallback: createLoading({ width: 360, height: 48 }),
});
const ThemeTimeline = loadable(() => import('components/Showcase/ThemeTimeline'), {
  ssr: false,
  fallback: createLoading({ width: 360, height: 180 }),
});

const ThemeDatePicker = loadable(() => import('components/Showcase/ThemeDatePicker'), {
  ssr: false,
  fallback: createLoading({ width: { md: 360, xl: 400 }, height: 260 }),
});
const NotificationCard = loadable(() => import('components/Showcase/NotificationCard'), {
  ssr: false,
  fallback: createLoading({ width: { md: 360, xl: 400 }, height: 146 }),
});
const ShipCard = loadable(() => import('components/Showcase/ShipImage'), {
  ssr: false,
  fallback: createLoading({ width: { md: 360, xl: 400 }, height: 146 }),
});

export default function Hero() {
  const frame = React.useRef<null | HTMLDivElement>(null);
  const globalTheme = useTheme();
  const isMdUp = useMediaQuery(globalTheme.breakpoints.up('md'));
  React.useEffect(() => {
    let obs: undefined | MutationObserver;
    function suppressTabIndex() {
      if (frame.current && isMdUp) {
        const elements = frame.current.querySelectorAll(
          'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
        );
        elements.forEach((elm) => {
          elm.setAttribute('tabindex', '-1');
        });
      }
    }
    if (typeof MutationObserver !== 'undefined' && frame.current) {
      obs = new MutationObserver(suppressTabIndex);
      obs.observe(frame.current, { childList: true, subtree: true });
    }
    return () => {
      if (obs) {
        obs.disconnect();
      }
    };
  }, [isMdUp]);
  return (
    <HeroContainer
      left={
        <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          <Typography variant="h1" sx={{ my: 2, maxWidth: 500 }}>
            Ship anywhere in the <GradientText>World</GradientText> with a click
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 3, maxWidth: 500 }}>
            Our online tools let you ship with confidence without downloading software.
          </Typography>
          <GetStartedButtons sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }} />
        </Box>
      }
      rightSx={{
        p: 3,
        minWidth: 2000,
        overflowY: 'auto',
        '& > div': {
          width: 360,
          display: 'inline-flex',
          verticalAlign: 'top',
          '&:nth-of-type(2)': {
            width: { xl: 400 },
          },
        },
        '&& *': {
          fontFamily: ['"IBM Plex Sans"', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(
            ','
          ),
        },
      }}
      rightRef={frame}
      right={
        <>
          {isMdUp && (
            <Stack spacing={4} sx={{ '& > .MuiPaper-root': { maxWidth: 'none' } }}>
              <TaskCard />
              <ThemeToggleButton />
              <ThemeTimeline />
            </Stack>
          )}
          {isMdUp && (
            <Stack spacing={4} sx={{ ml: 4, '& > .MuiPaper-root': { maxWidth: 'none' } }}>
              <ThemeDatePicker />
              <NotificationCard />
              <ShipCard />
            </Stack>
          )}
        </>
      }
    />
  );
}
