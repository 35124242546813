import loadable from '@loadable/component';
import { useInView } from 'react-intersection-observer';
import { brandingDarkTheme } from 'components/Context/createThemeContext';
import { ThemeProvider, Box, Container, Grid } from '@mui/material';
import MuiStatistics from './BringerStatistics';

const UserFeedbacks = loadable(() => import('./UserFeedbacks'));

const Testimonials = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '500px',
  });
  return (
    <ThemeProvider theme={brandingDarkTheme}>
      <Box ref={ref} sx={{ bgcolor: 'primaryDark.700' }}>
        <Container sx={{ py: { xs: 4, md: 8 } }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6} sx={{ zIndex: 1, minHeight: { xs: 400, sm: 307, lg: 355 } }}>
              {inView && <UserFeedbacks />}
            </Grid>
            <MuiStatistics />
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Testimonials;
