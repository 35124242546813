import { makeStyles } from '@mui/styles';
import Container from 'components/Container/Container';
import config from 'config';
import { memo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import BgSvg from 'components/Img/headerbg-min.jpg';
// import LatamPic from 'components/Img/latam-photo.jpeg';
import BACPic from 'components/Img/BACPic.png';
import BatteriesPic from 'components/Img/batteries.jpg';
import PerfumnsPic from 'components/Img/perfumns.jpg';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';

import { Typography, Grid, Paper, TextField, Button } from '@mui/material';

const useStyles = makeStyles(
  (theme) => ({
    '@keyframes peaceicon': {
      '0%': {
        animationTimingFunction: 'ease-in, ease-in-out',
        transform: 'matrix(1,0,0,1,3,8)',
      },
      '50%': {
        animationTimingFunction: 'ease-in, ease-in-out',
        transform: 'matrix(1,0,0,1,3,17)',
      },
      '100%': {
        animationTimingFunction: 'ease-in, ease-in-out',
        transform: 'matrix(1,0,0,1,3,8)',
      },
    },
    bgCont: {
      display: 'flex',
      color: '#fff',
      minHeight: 800,
      width: 'calc(100% + 2px)',
      position: 'relative',
      overflow: 'hidden',
    },
    bg: {
      display: 'flex',
      color: '#fff',
      backgroundImage: `url(${BgSvg})`,
      fallbacks: [
        {
          backgroundImage: 'linear-gradient(-30deg, #00004d 0%, #5333ed 49%, #2cd4d9 100%)',
        },
      ],
      minHeight: 800,
      width: 'calc(100% + 2px)',
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      position: 'absolute',
      transform: 'scale(1.3)',
      top: -120,
      '@media screen and (max-width: 767px)': {
        left: 0,
      },
    },
    bgOpacity: {
      backgroundColor: '#000',
      opacity: 0.3,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    bgContainerUp: {
      position: 'relative',
      display: 'flex',
      width: '100%',
    },
    container100: {
      height: '85%',
    },
    containerHeaderBottom: {},
    containerFeatures: {
      position: 'relative',
      top: -180,
      marginBottom: -100,
    },
    paperBox: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(4),
      height: 650,
    },
    paperFeatures: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(10),
    },
    papertracking: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(2),
      borderRadius: 10,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    typoWithIcon: {
      display: 'inline-flex',
    },
    iconsWithText: {
      fontSize: 28,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginRight: theme.spacing(3),
    },
    containerSecond: {
      marginTop: theme.spacing(12),
      marginBottom: theme.spacing(12),
    },
    containerBlue: {
      backgroundColor: '#2249bf',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(10),
      '@media screen and (max-width: 767px)': {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
        padding: theme.spacing(2),
      },
    },
    containerContentSecond: {
      textAlign: 'center',
    },
    peaceicon: {
      '& g > g > g > g:nth-child(2)': {
        animationName: '$peaceicon',
        animationDuration: '3s',
        animationIterationCount: 'infinite',
      },
    },
    textFieldTracking: {
      height: 50,
      paddingLeft: 20,
      paddingRight: 20,
    },
    buttonTracking: {
      height: 50,
    },
    paperTrackingContentRound: {
      borderRadius: 30,
    },
    titles: {
      marginBottom: 20,
    },
    circleNumbersCont: {
      position: 'relative',
      marginBottom: 10,
    },
    lineNumbers: {
      height: 2,
      backgroundColor: '#000',
      position: 'absolute',
      top: 25,
      left: 0,
      right: 0,
      width: '100%',
      zIndex: -1,
    },
    circleNumbers: {
      margin: '0 auto',
      backgroundColor: '#000',
      borderRadius: 50,
      width: 50,
      height: 50,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titlePaper: {
      height: 80,
    },
    imagePaperLatam: {
      width: '100%',
      marginBottom: 10,
      height: 250,
      backgroundImage: `url(${BACPic})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
    },
    imagePaperBatteries: {
      width: '100%',
      marginBottom: 10,
      height: 250,
      backgroundImage: `url(${BatteriesPic})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
    },
    imagePaperPerfumns: {
      width: '100%',
      marginBottom: 10,
      height: 250,
      backgroundImage: `url(${PerfumnsPic})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
    },
    textAlignRight: {
      textAlign: 'right',
    },
    buttonBottomHead: {
      color: '#fff',
    },
  }),
  { name: 'BringerHome' }
);

const Home = memo(
  () => {
    const intl = useIntl();
    const classes = useStyles();
    const inputTrackingRef = useRef();

    const accountByDomain = useAppSelector((rstate) => rstate.accounts.result.accountByDomain?.[0]);

    const navigate = useNavigate();

    const goToTracking = () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'undefined... Remove this comment to see the full error message
      const { value } = inputTrackingRef.current;

      if (!value) {
        return;
      }

      navigate(`/tracking/${value}`);
    };

    const title = accountByDomain
      ? `${accountByDomain.name} Parcel Services`
      : 'Bringer Parcel Services';
    // eslint-disable-next-line
    const description =
      'Ship anywhere in the world with a click. Our online tools let you ship with confidence without downloading software';

    const ldjson = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.domain,
      name: config.app.title,
      alternateName: 'Bringer',
      image: `${config.domain}/bps-logo-white.png`,
      sameAs: [
        'https://www.facebook.com/Bringer',
        'https://instagram.com/Bringer',
        'https://www.linkedin.com/company/Bringer/',
        'https://twitter.com/@Bringer',
      ],
    };

    const ldjsonOrg = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: config.app.title,
      legalName: 'Bringer CORP',
      email: `mailto:contact@${config.host}`,
      url: config.domain,
      logo: `${config.domain}/bps-logo-white.png`,
      sameAs: [
        'https://www.facebook.com/Bringer',
        'https://instagram.com/Bringer',
        'https://www.linkedin.com/company/Bringer/',
        'https://twitter.com/@Bringer',
      ],
    };

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={`${config.domain}/`} />
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="twitter:title" content={title} />
          <meta name="og:description" content={description} />
          <meta name="twitter:description" content={description} />
          <meta property="og:type" content="website" />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json">{JSON.stringify(ldjson)}</script>
          <script type="application/ld+json">{JSON.stringify(ldjsonOrg)}</script>
        </Helmet>
        <div className={classes.bgCont}>
          <div className={classes.bg} />
          <div className={classes.bgOpacity} />
          <div className={classes.bgContainerUp}>
            <Container>
              <Grid container alignItems="center" className={classes.container100}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h2" component="h1" color="inherit" gutterBottom>
                    {intl.formatMessage({
                      id: 'ShipWithAClick',
                      defaultMessage: 'Ship anywhere in the world with a click.',
                    })}
                  </Typography>
                  <Typography variant="h4">
                    {intl.formatMessage({
                      id: 'homeText1',
                      defaultMessage:
                        'Our online tools let you ship with confidence without downloading software',
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <div className={classes.containerFeatures}>
          <Container>
            <Paper className={classes.papertracking}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <Paper className={classes.paperTrackingContentRound}>
                    <TextField
                      inputRef={inputTrackingRef}
                      InputProps={{ disableUnderline: true, className: classes.textFieldTracking }}
                      fullWidth
                      placeholder="Please enter a tracking number here..."
                      variant="standard"
                      name="tracking"
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paperTrackingContentRound}>
                    <Button onClick={goToTracking} fullWidth className={classes.buttonTracking}>
                      Track Parcel
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </div>
        {!accountByDomain && (
          <>
            <Container>
              {/* <Button component={Link} to="/docs"> */}
              <Grid container className={classes.containerSecond} spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h5" align="center" className={classes.titles}>
                    Scale your operations by integrating with our API (Beta)
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    Bringer Parcel included an unparalleled shipping experience with every order you
                    send.
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    All the tools you need to exceed your customers expectations.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={6} sm={2}>
                      <div className={classes.circleNumbersCont}>
                        <div className={classes.lineNumbers} />
                        <div className={classes.circleNumbers}>1</div>
                      </div>
                      <Typography align="center" paragraph>
                        Bulk Import your parcel
                      </Typography>
                      <Typography align="center" variant="body2">
                        Import your parcel information directly into BPS with our CSV template
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <div className={classes.circleNumbersCont}>
                        <div className={classes.lineNumbers} />
                        <div className={classes.circleNumbers}>2</div>
                      </div>
                      <Typography align="center" paragraph>
                        Shipping made easy
                      </Typography>
                      <Typography align="center" variant="body2">
                        It is as eas as: book your parcel, print your label
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <div className={classes.circleNumbersCont}>
                        <div className={classes.lineNumbers} />
                        <div className={classes.circleNumbers}>3</div>
                      </div>
                      <Typography align="center" paragraph>
                        Complete tracking history
                      </Typography>
                      <Typography align="center" variant="body2">
                        From receiving to delivery, we have got it covered
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <div className={classes.circleNumbersCont}>
                        <div className={classes.lineNumbers} />
                        <div className={classes.circleNumbers}>4</div>
                      </div>
                      <Typography align="center" paragraph>
                        Simplified custom declarations
                      </Typography>
                      <Typography align="center" variant="body2">
                        Bringer Parcel converts your parcel data into the appropiate customs
                        declarations
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <div className={classes.circleNumbersCont}>
                        <div className={classes.lineNumbers} />
                        <div className={classes.circleNumbers}>5</div>
                      </div>
                      <Typography align="center" paragraph>
                        Grow with BPS
                      </Typography>
                      <Typography align="center" variant="body2">
                        Scale your operations with our API
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* </Button> */}
            </Container>
            <br />
            <br />
            <Grid container className={classes.containerBlue} spacing={5}>
              <Grid item xs={12}>
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paperBox}>
                      <div className={classes.imagePaperLatam} />
                      <Typography variant="h6" gutterBottom className={classes.titlePaper}>
                        Your e-commerce gateway to Latin America
                      </Typography>
                      <Typography variant="body2">
                        Being a subsidary of a cargo airline has its advantages! BPS leverages
                        Bringer Air Cargo's extensive network, providing you the access to ship
                        parcels direct to consumers in Latin America. This includes the flexibility
                        of offering both DDU (delivered duties unpaid) and DDP (delivered duties
                        prepaid) import modalities
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paperBox}>
                      <div
                        className={classes.imagePaperPerfumns}
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string; alt: string; src: any; ... Remove this comment to see the full error message
                        alt="Gateway to Latin America"
                        src={PerfumnsPic}
                      />
                      <Typography variant="h6" gutterBottom className={classes.titlePaper}>
                        Shipping parcels containing perfume?
                      </Typography>
                      <Typography variant="body2">
                        Unlike other common couriers, we DO accept parcels containing perfumes.
                        Parcels are shiped in accordance with IATA DG Regulations. Simply select
                        contains Perfume when booking your parcel and BPS takes care of the
                        Dangerous Goods Declaration.
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paperBox}>
                      <div
                        className={classes.imagePaperBatteries}
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string; alt: string; src: any; ... Remove this comment to see the full error message
                        alt="Gateway to Latin America"
                        src={BatteriesPic}
                      />
                      <Typography variant="h6" gutterBottom className={classes.titlePaper}>
                        Shipping parcels containing lithium batteries?
                      </Typography>
                      <Typography variant="body2">
                        BPS simplifies the hassle of shipping lithium batteries. Select contains
                        Lithium Battery when booking your parcel and we handle the rest. Parcels are
                        shipped in accordance with IATA DG Regulations. Only lithium batteries under
                        classification UN3481 are accepted.
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <br />
        <br />
      </>
    );
  },
  () => true
);

export default Home;
