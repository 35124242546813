import AppFooter from 'components/AppFooter/AppFooter';
import { Divider, styled } from '@mui/material';
import Testimonials from './Testimonials';
import References, { CORE_CUSTOMERS } from './Reference';
// import ProductSuite from 'docs/src/components/home/ProductSuite';
// import ValueProposition from 'docs/src/components/home/ValueProposition';
// import DesignSystemComponents from 'docs/src/components/home/DesignSystemComponents';
// import HeroEnd from 'docs/src/components/home/HeroEnd';
import Hero from './Hero';
// import NewsletterToast from 'docs/src/components/home/NewsletterToast';

const Airplane = styled('div')({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: 'absolute',
  overflow: 'hidden',
  '.airplane': {
    top: 180,
    left: 0,
    right: 0,
    position: 'absolute',
    zIndex: 1,
    animation: 'moving 20s linear infinite',
  },
  '& .flame': {
    top: -25,
    left: 40,
    height: 5,
    width: 30,
    position: 'relative',
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    animation: 'flame 0.3s linear infinite',
    zIndex: -1,
  },
  '& .flame2': {
    top: -70,
    left: 30,
  },
  '@keyframes flame': {
    '0%': {
      transform: 'translateX(0%)',
    },
    '50%': {
      transform: 'translateX(50%)',
    },
    '100%': {
      transform: 'translateX(0%)',
    },
  },
  '@keyframes moving': {
    '0%': {
      transform: 'translate(100%, 100%)',
    },
    '10%': {
      transform: 'translate(80%, 80%)',
    },
    '20%': {
      transform: 'translate(80%, 70%)',
    },
    '30%': {
      transform: 'translate(70%, 60%)',
    },
    '40%': {
      transform: 'translate(60%, 40%)',
    },
    '50%': {
      transform: 'translate(32%, 32%)',
    },
    '60%': {
      transform: 'translate(20%, 45%)',
    },
    '70%': {
      transform: 'translate(10%, 30%)',
    },
    '80%': {
      transform: 'translate(10%, 20%)',
    },
    '100%': {
      transform: 'translate(-30%, 10%)',
    },
  },
});

export default function Home() {
  return (
    <>
      <Airplane>
        <div className="airplane">
          <img alt="airplane" width={100} src="https://i.ibb.co/SPpRcJz/airplane.png" />

          <div className="flame" />
          <div className="flame flame2" />
        </div>
      </Airplane>
      <Hero />
      <References companies={CORE_CUSTOMERS} />
      {/* <ProductSuite /> */}
      {/* <ValueProposition /> */}
      {/* <DesignSystemComponents /> */}
      <Testimonials />
      {/* <HeroEnd /> */}
      <Divider />
      <AppFooter />
    </>
  );
}
