import loadable from '@loadable/component';
import { useInView } from 'react-intersection-observer';
import { Box, Container, Typography } from '@mui/material';
import {
  CORE_CUSTOMERS,
  ADVANCED_CUSTOMERS,
  DESIGNKITS_CUSTOMERS,
  TEMPLATES_CUSTOMERS,
} from './CompaniesGrid';

export { CORE_CUSTOMERS, ADVANCED_CUSTOMERS, DESIGNKITS_CUSTOMERS, TEMPLATES_CUSTOMERS };

const CompaniesGrid = loadable(() => import('./CompaniesGrid'));

const References = ({
  companies,
}: {
  companies:
    | typeof CORE_CUSTOMERS
    | typeof ADVANCED_CUSTOMERS
    | typeof DESIGNKITS_CUSTOMERS
    | typeof TEMPLATES_CUSTOMERS;
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  return (
    <Container ref={ref} sx={{ py: { xs: 4, sm: 6, md: 10 } }}>
      <Box sx={{ minHeight: { xs: 236, sm: 144, md: 52 } }}>
        {inView && <CompaniesGrid data={companies} />}
      </Box>
      <Typography
        color={(theme) => (theme.palette.mode === 'dark' ? 'grey.500' : 'grey.800')}
        textAlign="center"
        variant="body2"
        sx={{
          minHeight: 42, // hard-coded to reduce CLS (layout shift)
          mt: 4,
          mx: 'auto',
          maxWidth: 400,
        }}
      >
        We partnered with the most important companies in the world.
      </Typography>
    </Container>
  );
};

export default References;
