import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BringerLogo from 'components/Img/logo-1.png';
import BringerLogoWhite from 'components/Img/bpswhite.png';
import EmailSubscribe from 'components/EmailSubscribe/EmailSubscribe';
import ROUTES from 'route';
// import FEATURE_TOGGLE from 'docs/src/featureToggle';
import Link from 'components/Link/Link';
import { Box, Container, Divider, Typography, IconButton, Stack, useTheme } from '@mui/material';
// import SvgStackOverflow from 'docs/src/icons/SvgStackOverflow';

const FEATURE_TOGGLE = { nav_products: true };

export default function AppFooter() {
  const themeMain = useTheme();
  return (
    <Container component="footer">
      <Box
        sx={{
          py: 8,
          display: 'grid',
          gridAutoColumns: '1fr',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 4,
          gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1.75fr', lg: '1fr 1fr' },
          gridTemplateRows: 'auto',
          '& a:not(.MuiIconButton-root)': {
            mt: 1,
            color: 'text.secondary',
            typography: 'body2',
            '&:hover': {
              color: 'primary.main',
              textDecoration: 'underline',
            },
          },
        }}
      >
        <div>
          <img
            src={themeMain.palette.mode === 'light' ? BringerLogo : BringerLogoWhite}
            alt="bringer parcel services"
            width={80}
          />
          <Typography variant="body2" fontWeight="bold" sx={{ pt: 2 }}>
            Join our newsletter!
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            No spam, guaranteed.
          </Typography>
          <EmailSubscribe sx={{ mb: 1 }} />
        </div>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr 1fr', md: '1fr 1fr 1fr 1fr' },
            gridAutoColumns: '1fr',
            gap: 2,
          }}
        >
          {FEATURE_TOGGLE.nav_products ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontWeight="bold" variant="body2">
                Products
              </Typography>
              <Link href={ROUTES.productCore}>Express</Link>
              <Link href={ROUTES.productAdvanced}>Standard</Link>
              <Link href={ROUTES.productTemplates}>One Day Air</Link>
            </Box>
          ) : (
            <Box sx={{ display: { xs: 'none', md: 'block' } }} />
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontWeight="bold" variant="body2">
              Resources
            </Typography>
            <Link href={ROUTES.materialIcons}>Api</Link>
            <Link href={ROUTES.freeTemplates}>Comercial Assistance</Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontWeight="bold" variant="body2">
              Explore
            </Typography>
            <Link href={ROUTES.documentation}>Documentation</Link>
            <Link href={ROUTES.languages}>Languages</Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontWeight="bold" variant="body2">
              Company
            </Typography>
            <Link href={ROUTES.about}>About</Link>
            <Link href={ROUTES.vision}>Vision</Link>
            <Box sx={{ display: 'flex', alignItems: 'end' }}>
              <Link href={ROUTES.careers}>Careers </Link>
              <Box
                sx={{
                  px: 0.5,
                  py: 0.3,
                  ml: 1,
                  borderRadius: 0.5,
                  fontSize: (theme) => theme.typography.pxToRem(9),
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  color: '#fff',
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.error[800]
                      : theme.palette.error.main,
                }}
              >
                Hiring
              </Box>
            </Box>
            <Link href={ROUTES.support}>Support</Link>
            <Link target="_blank" rel="noopener noreferrer" href="mailto:contact@bringer.com">
              Contact us
            </Link>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          py: 4,
          display: { xs: 'block', sm: 'flex' },
          alignItems: { sm: 'center' },
          justifyContent: { sm: 'space-between' },
        }}
      >
        <Typography color="text.secondary" variant="body2">
          Copyright © {new Date().getFullYear()} Bringer Corp.
        </Typography>
        <Box sx={{ py: { xs: 2, sm: 0 } }}>
          <Stack spacing={2} direction="row">
            <IconButton
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/bringerparcel"
              aria-label="twitter"
              title="Twitter"
              size="small"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/bringerparcel/"
              aria-label="linkedin"
              title="LinkedIn"
              size="small"
            >
              <LinkedInIcon />
            </IconButton>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
