import KeyboardArrowRightRounded from '@mui/icons-material/KeyboardArrowRightRounded';
import ContentCopyRounded from '@mui/icons-material/ContentCopyRounded';
import ROUTES from 'route';
import Link from 'components/Link/Link';
import { Box, Button, BoxProps } from '@mui/material';

export default function GetStartedButtons({
  installation = 'Start Saving Money',
  to = ROUTES.documentation,
  ...props
}: { installation?: string; to?: string } & BoxProps) {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '&& > *': { minWidth: 'clamp(0px, (492px - 100%) * 999 ,100%)' },
        ...props.sx,
      }}
    >
      <Button
        href={to}
        component={Link}
        noLinkStyle
        size="large"
        variant="contained"
        endIcon={<KeyboardArrowRightRounded />}
      >
        Get started
      </Button>
      <Box sx={{ width: 16, height: 16 }} />
      <Button
        size="large"
        // @ts-expect-error hide this
        variant="code"
        startIcon="$"
        endIcon={<ContentCopyRounded />}
      >
        {installation}
      </Button>
    </Box>
  );
}
